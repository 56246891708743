<template>
    <div class="chart">
        <div class="chart-title">
            <img src="@/assets/aggroTrace/down.png" alt="">
            <span>销量统计</span>
        </div>
        <div class="echarts" id="echarts1"></div>
    </div>
</template>
<script>
import * as echarts from "echarts";
import {  groupBy } from './commom.js'
export default{
    data(){
        return{
            option:{
                grid:{
                    left: '10%',
                    right: '10%',
                    top:35,
                    bottom: 30
                },
                title: {
                    show:false
                },
                legend: {
                    show:true,
                    icon: 'rect',
                    itemWidth:10,
                    itemHeight:10,
                    textStyle:{
                        color:'#fff'
                    }
                },
                xAxis: {
                    data: ['Mon', 'Tue', 'Wed', 'Thu'],
                    axisLabel: {
                      color: '#fff'
                    },
                    axisTick: {
                      show: false
                    },
                    axisLine: {
                     show: true,
                     lineStyle:{
                        color: 'rgba(28,93,100,0.9)',
                      }
                    },
                    z: 10
                },
                yAxis: {
                    axisLine: {
                      show: false,
                    },
                    axisLabel: {
                       color: '#fff'
                    },
                    splitLine:{
                        lineStyle:{
                            color: 'rgba(255,255,255,0.3)',
                            type: 'dashed'
                        }
                    }
                },
                series: [
                    {
                        name: '2022',
                        type: 'bar',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: 'rgba(255,163,4,1)' },
                            { offset: 1, color: 'rgba(255,163,4,0.1)' }
                            ])
                        },
                        data: [120, 200, 150, 80, 70, 110, 130]
                    },
                    {
                        name: '2023',
                        type: 'bar',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: 'rgba(98,215,255,1)' },
                            { offset: 1, color: 'rgba(98,215,255,0.1)' }
                            ])
                        },
                        data: [110, 80, 100, 80, 70, 70, 50]
                    },
                    {
                        name: '2024',
                        type: 'bar',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: 'rgba(32,208,140,1)' },
                            { offset: 1, color: 'rgba(32,208,140,0.1)' }
                            ])
                        },
                        data: [100, 120, 80, 90, 140, 130, 110]
                    },
                ]
            }
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        init(){
            const colorArr = [{
                color1: 'rgba(255,163,4,1)',
                color2: 'rgba(255,163,4,0.1)',
            },{
                color1: 'rgba(98,215,255,1)',
                color2: 'rgba(98,215,255,0.1)',
            },{
                color1: 'rgba(32,208,140,1)',
                color2: 'rgba(32,208,140,0.1)',
            }]
            const myChart = echarts.init(document.getElementById('echarts1'));
            this.$get('/digitalAgriculture/getDigitalAgriculture',{page:1,size:100}).then((res) => {
                if (res.data.state == 'success') {
                    const data = res.data.data;
                    const lastData = data.productionValues;
                    //console.log('lastData',lastData)
                    this.option.series = Object.keys(lastData).map((v,i)=>{
                        this.option.xAxis.data = lastData[v]
                        return {
                            name: v,
                            type: 'bar',
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: colorArr[i].color1 },
                                { offset: 1, color: colorArr[i].color2 }
                                ])
                            },
                            data: lastData[v]
                        }
                   })
                   myChart.setOption(this.option);
                }
            });
        },

        
    }
}
</script>
<style lang="scss" scoped>
.chart{
    width: 50%;
    height: 100%;
    &-title{
        height: 40px;
        background-color: rgba(20,103,105,0.4);
        margin: 10px 10%;
        display: flex;
        color: #fff;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        img{
            width: 35px;
            margin-right: 10px;
        }
    }
}
.echarts{
    width: 100%;
    height: calc(100% - 50px);
}
</style>