<template>
  <div class="pie">
    <div class="lengend">
        <div><span style="background-color: #7586D9;"></span>全部农事任务  {{ lengthObj.totalLength }}</div>
        <div><span style="background-color: #30CFEC;"></span>进行中任务   {{ lengthObj.pendLength }}</div>
        <div><span style="background-color: #FDA205;"></span>未完成任务  {{ lengthObj.notLength }}</div>
        <div><span style="background-color: #67D470;"></span>已完成任务  {{ lengthObj.finishLength }}</div>
    </div>
    <div id="pieChart"></div>
  </div>
  
</template>
<script>
// 获取最近30天的开始时间、结束时间
function timestampToDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
}
function get30Days(){
  let toData = new Date(new Date().toLocaleDateString()).getTime()
  let past30daysStart = toData - 30 * 3600 * 24 * 1000
	let past30daysEnd = (past30daysStart) + 24 * 60 * 60 * 1000 - 1
  return [timestampToDate(past30daysEnd),timestampToDate(toData)]
}

var Highcharts = require("highcharts/highstock");

import {  groupBy } from './commom.js'

export default {
  data() {
    return {
      option: {
        chart: {
          type: "pie",
          backgroundColor: "rgba(255, 255, 255, 0)", // 设置背景颜色透明 默认是白色
          options3d: {
            enabled: true, //显示图表是否设置为3D
            alpha: 55, // 内旋转角度 从前后看 我理解为以x轴为基准的旋转
            beta: 0, // 外旋转角度 从左右看 我理解为以z轴为基准的旋转  不知道咋形容，用的时候可以都试试 我这样设置的话饼图是躺着的
          },
          animation: false,
        },
        title: {
          text: "", // 饼图的标题
        },
        subtitle: {
          text: "", // 饼图的副标题
        },
        legend: {
            y: 15, // 距离y轴的距离
            layout:"", // 指定图例的排列方式，horizontal水平排列，vertical垂直排列
            align: "left", // 指定图例、文本水平对齐方式，left、center和right
            verticalAlign: "top", // 指定元素在容器中的垂直对齐方式，top向上对齐，middle居中对齐，bottom向下对齐
            itemDistance: 30,
            symbolRadius:0
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            innerSize: "60%",
            size: 180, // 设置饼图大小
            depth: 45,
            dataLabels: {
              enabled: true, // 显示连线
              distance: "5%", //连线长度
              formatter: function () {
                //this 为当前的点（扇区）对象，可以通过  //console.log(this) 来查看详细信息
                return '<span"> ' + this.y + "%</span>";
              },
              style: {
                // 标题样式设置
                color: "#FFFFFF", //字体颜色
                fontSize: "12px", //字体大小
                fontWeight: "400", // 字体粗细
                textOutline: "none", // 去除黑色阴影
              },
            },
            states: {
              halo: {
                pacity: 0.5,
              },
            },
          },
        },
        tooltip: {
          // 提示设置
          crosshairs: true,
          enabled: false, //false 不显示提示框
        },
        credits: {
          enabled: false, // 禁用版权信息
        },
        series: [
          {
            name: "",
            type: "pie",
            data: [],
            // [
            //   {
            //     name: "进行中任务",
            //     sliced: true,
            //     selected: true,
            //     y: 12,
            //     color: "rgb(48, 207, 236,0.6)",
            //   },
            //   {
            //     name: "未完成任务",
            //     sliced: true,
            //     selected: true,
            //     y: 46,
            //     color: "rgba(62, 180, 255,0.9)",
            //   },
            //   {
            //     name: "已完成任务",
            //     sliced: true,
            //     selected: true,
            //     y: 34,
            //     color: "rgba(103, 212, 112,0.7)",
            //   },
            // ]
            showInLegend: false 
          },
        ],
      },
      startTime: get30Days()[0],
      endTime: get30Days()[1],
      lengthObj: {}
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const typeList =[{type: 0,text: '进行中任务',color: "rgba(48, 207, 236,0.5)"},{type: 1,text: '已完成任务', color: "rgba(103, 212, 112,0.5)"},{type: 2,text: '未完成任务',color: "rgba(253,162,5,0.5)"}]
      // 最近30天的农事活动
      this.$get('/farmTask/getFarmTaskList',{
                page: 1,
                size: 100,
                startTime:this.startTime,
                endTime: this.endTime
            }).then((res) => {
                if (res.status == 200) {
                   const data = res.data.datas
                   const totalLength = data.length
                   const pendLength = data.filter(v=>v.taskType == 0).length
                   const finishLength = data.filter(v=>v.taskType == 1).length
                   const notLength = data.filter(v=>v.taskType == 2).length
                   this.lengthObj = {
                    totalLength,pendLength,finishLength,notLength
                   }
                   const groupedItems  = groupBy(data, 'taskType');
                   this.option.series[0].data = Object.keys(groupedItems).map(v=>{
                    return {
                      name: typeList.filter(m=>m.type == v)[0].text,
                      sliced: true,
                      selected: true,
                      y: groupedItems[v].length/totalLength *100,
                      color:typeList.filter(m=>m.type == v)[0].color,
                    }
                   })
                   Highcharts.chart("pieChart",this.option)
                }
            });
      
    },
    },
};
</script>
<style lang="scss" scoped>
.pie{
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    justify-content: flex-end;
    #pieChart {
       height:100%;
       width: 80%;
       margin-right: -15%;
    }
    .lengend{
        position: absolute;
        left: 5%;
        top: 10%;
        
        &>div{
            position: relative;
            width: 150px;
            height: 30px;
            line-height: 30px;
            margin-top: 10px;
            padding-left: 20px;
            background-color: rgba(40,118,135,0.5);
            font-size: 14px;
            color: #fff;
        }
        &>div>span{
            position: absolute;
            width: 10px;
            height: 10px;
            top: 10px;
            left: 5px;
        }
    }
}
</style>
