<template>
    <div class="title">
        <span>{{ text }}</span>
    </div>
</template>
<script>
export default{
    props:{
        text:{
            type: String,
            default: '标题'
        }
    },
    data(){
        return{
        }
    }
}
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  background: url("../../assets/index/boxtitle.png") no-repeat;
  background-size: 100% 100%;
  font-size: 20px;
  font-family: "mokuaiTitile";
  font-weight: bold;
  line-height: 45px;
  font-style: normal;
  span {
    margin-left: 6%;
    color: #fff;
  }
}
</style>