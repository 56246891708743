export function groupBy(array, key) {
    return array.reduce((result, currentItem) => {
    // 使用 key 函数（如果有）转换 key
    const groupKey = typeof key === 'function' ? key(currentItem) : currentItem[key];
    // 确保 result 对象的该键已经是数组
    if (!result[groupKey]) {
        result[groupKey] = [];
    }
    // 将当前项推入对应的数组
    result[groupKey].push(currentItem);
    return result;
    }, {});
} 